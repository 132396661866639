<template>
  <div class="change-operators-staking-dao-container wrapper">
    <CRow>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="stakingDaoDetailsUrl">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('STAKING_DAO_DETAILS_PAGE_BACK_LINK_BUTTON') }}
            </router-link>
          </CCardHeader>
          <CCardBody class="body">
            <h4>{{ $t('STAKING_DAO_CHANGE_OPS_SELECT_OPERATORS_BTN') }}</h4>
            <section class="operator-tags">
              <span class="operator-tag" v-for="op in selectedOperators" :key="op.id">
                <button type="button" class="btn btn-primary btn-sm rounded-pill operator-tag">
                  {{ op.name }}&nbsp;&nbsp;<span>&#x2715;</span>
                </button>
              </span>
            </section>
            <section class="action-btns">
              <div class="d-grid gap-4 d-md-flex justify-content-md-end">
                <button class="btn btn-cancel me-md-2 rounded-pill" type="button">{{ $t('STAKING_DAO_CHANGE_OPS_CANCEL_BTN') }}</button>
                <button class="btn btn-active rounded-pill" type="button">{{ $t('STAKING_DAO_CHANGE_OPS_SAVE_BTN') }}</button>
              </div>
            </section>
            <section class="search-bar">
              <div class="input-group">
                <input
                  v-model="searchInput"
                  type="text"
                  class="form-control"
                  :placeholder="$t('STAKING_DAO_CHANGE_OPS_SEARCH_PLACEHOLDER')"
                />
              </div>
            </section>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { Constants } from '@/constants';
import { mapGetters } from 'vuex';
import endpoints from '@/constants/endpoints';
import { env } from '@/constants/env';
import responseHelper from '@/utils/response-helper';
import _ from 'lodash';
export default {
  name: 'StakingDaoChangeOperators',
  components: {},
  data() {
    return {
      stakingDaoDetailsUrl: `/admin/staking-dao/${this.$route.params.id}/details`,
      instanceDetails: null,
      isLoading: false,
      selectedOperators: [],
      id: this.$route.params.id,
      searchInput: '',
      operators: [],
    };
  },
  computed: {
    etherscanLink() {
      return !env.ssv.isTestnet ? 'https://etherscan.io' : 'https://goerli.etherscan.io';
    },
  },
  watch: {
    searchInput() {
      this.debounceSearch();
    },
  },
  async mounted() {
    await Promise.all([this.getStakingDaoInstanceDetails(this.id), this.getOperators()]);
    this.bindSelectedOperators();
  },
  methods: {
    async getStakingDaoInstanceDetails(id) {
      try {
        this.isLoading = true;
        const res = await this.$http.get(endpoints.getStakingDaoInstanceDetails(id));
        this.instanceDetails = res.data;
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('STAKING_DAO_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    bindSelectedOperators() {
      this.selectedOperators = [...this.instanceDetails.operators];
    },
    debounceSearch: _.debounce(function () {
      console.log(this.searchInput);
    }, 500),
    async getOperators() {
      try {
        this.isLoading = true;
        const res = await this.$http({
          method: 'get',
          baseURL: env.apiPartnerUrl,
          url: endpoints.getOperators,
        });
        console.log(res.data);
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('STAKING_DAO_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.change-operators-staking-dao-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }
  }

  .body {
    position: relative;
    .operator-tags {
      .operator-tag {
        margin-right: 2px;
        margin-top: 2px;
      }
    }
    .action-btns {
      @media (min-width: 767px) {
        position: absolute;
        top: 20px;
        right: 20px;
      }
      @media (max-width: 766px) {
        float: right;
      }
      button {
        margin-left: 4px;
      }
    }
    .search-bar {
      margin-top: 20px;
    }
  }

  .btn-active {
    margin-top: 8px;
    min-width: 145px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #fff;
    background-color: #5da30b;
    border: none;
  }
  .btn-cancel {
    margin-top: 8px;
    min-width: 145px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #fff;
    background-color: red;
    border: none;
  }
  .btn-disabled {
    margin-top: 8px;
    min-width: 145px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #fff;
    background-color: #5b5b5b;
    border: none;
    cursor: not-allowed;
  }
}
</style>
